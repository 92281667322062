import {useEffect} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'

export function Logout() {
 // const {logout} = useAuth()
  const logout = () => {
      localStorage.removeItem("lava20_admin")
  }
  useEffect(() => {
    logout()
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Route>
        <Route path='*' element ={ <Navigate to='/auth/login' /> } />
      
      </Route>
    </Routes>
  )
}
